/**
 * Functional programming replacement for Switch and if-else statements.
 * Main benefit is to scope and return the desired variable instead of
 * using the 'define variable then assign via switch-statement' pattern.
 *
 * @param key
 * @resource https://codeburst.io/alternative-to-javascripts-switch-statement-with-a-functional-twist-3f572787ba1c
 * @returns matchedValue
 */
export const match = x => ({
	on: (pred, fn) => (pred(x) ? matched(fn(x)) : match(x)),
	otherwise: fn => fn(x),
});

const matched = x => ({
	on: () => matched(x),
	otherwise: () => x,
});
